import React, { useEffect } from 'react';
import { classes, st } from './FormInputs.st.css';
import { BenefitType } from '@wix/ambassador-pricing-plans-v2-benefit/types';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import {
  FormRef,
  FormView,
  MessageType,
  SubmissionValue,
  ValidateFieldArgs,
  ComplexPhoneData,
  TranslateArgs,
} from '@wix/forms-ui/types';
import { FieldType } from '@wix/ambassador-forms-v2-form/types';
import { Form } from '@wix/forms-ui/tpa';
import settingsParams from '../../settingsParams';
import { useSettings } from '@wix/tpa-settings/react';
import { FormInputsDataHooks } from './dataHooks';
import { Member } from '@wix/ambassador-members-ng-api/types';
import {
  BookingRequestKeyMappings,
  mapMemberDetailsToDisplayPropertiesValues,
  getFieldFromSchema,
  disableField,
  setFieldValue,
} from '../../../../utils/mappers/form-submission.mapper';
import { useFormActions } from '../../Hooks/useFormActions';
import { DesignTabSubSettings } from '../../../../types/types';
import {
  createDummyEmptySubmission,
  createDummySubmission,
} from '../../../../utils/dummies/dummy-data';
import { useEditorContext } from '../../Hooks/useEditorContext';
import {
  isComplexPhoneField,
  validateComplexPhone,
  validateEmail,
} from '../../../../utils/form-validations';
import { getServiceSlotIdentifier, mapToArray } from '../../../../utils';
import { ServiceData, SlotService } from '../../../../utils/state/types';
import { ExperimentsConsts } from '../../../../consts/experiments';
import { useFormComponentContext } from '../../Hooks/useFormComponentContext';

export interface FormInputsProps {
  formSchema: FormView;
  memberDetails?: Member;
  formRef: React.MutableRefObject<FormRef | undefined>;
  overrideDefaultFieldsValues?: boolean;
  serviceData: ServiceData;
  editCollapseData?: boolean;
}

const FormInputs: React.FC<FormInputsProps> = ({
  formSchema,
  memberDetails,
  formRef,
  overrideDefaultFieldsValues = false,
  serviceData,
}) => {
  const { setNumberOfParticipants, setEmail } = useFormActions();
  const { experiments } = useExperiments();
  const settings = useSettings();
  const { t } = useTranslation();
  const { isMobile, isEditor } = useEnvironment();
  const editorContext = useEditorContext();
  const { dateRegionalSettingsLocale } = useFormComponentContext();

  const isFormA11yEnabled = experiments.enabled(ExperimentsConsts.FormA11y);

  // a bit of an hack, we only need the information about the service as part of updating the number of participants, which is relevant only in cases of course or class (which will be a single slot)
  const singleSlotService = mapToArray<SlotService>(
    serviceData.slotServices,
  )[0];
  const selectedPaymentOption = singleSlotService.selectedPaymentOption;

  const fillInMemberDetails = () => {
    formRef!.current!.setFieldDisplayProperties(
      mapMemberDetailsToDisplayPropertiesValues(
        [
          BookingRequestKeyMappings.EMAIL,
          BookingRequestKeyMappings.PHONE,
          BookingRequestKeyMappings.FIRST_NAME,
          BookingRequestKeyMappings.LAST_NAME,
          BookingRequestKeyMappings.FULL_ADDRESS,
        ],
        formSchema,
        memberDetails!,
        overrideDefaultFieldsValues,
      ),
    );
    setEmail(memberDetails?.contact?.emails?.[0] || memberDetails?.loginEmail);
  };

  const updateDummyContent = () => {
    formRef!.current!.setFieldDisplayProperties(
      editorContext.selectedSettingsSubTabId === DesignTabSubSettings.TEXT
        ? createDummySubmission(t)
        : createDummyEmptySubmission(),
    );
  };

  useEffect(() => {
    if (memberDetails) {
      fillInMemberDetails();
    }
  }, [memberDetails?.id, formSchema?.fields?.length]);

  useEffect(() => {
    // checking if over editor since useEffect is always called over editor (even if editorContext did not change) - probably an editor-flow gap
    if (isEditor && editorContext.isDummy) {
      updateDummyContent();
    }
  }, [editorContext]);

  useEffect(() => {
    const isPlanUnlimited =
      selectedPaymentOption?.benefitInfo?.benefit?.benefitType ===
      BenefitType.UNLIMITED;

    if (isPlanUnlimited) {
      setFieldValue(
        formSchema,
        BookingRequestKeyMappings.NO_OF_PARTICIPANTS,
        formRef,
        '1',
      );

      setNumberOfParticipants(
        1,
        getServiceSlotIdentifier(singleSlotService.nestedSlot),
      );
    }

    disableField(
      formSchema,
      BookingRequestKeyMappings.NO_OF_PARTICIPANTS,
      formRef,
      isPlanUnlimited,
    );
  }, [selectedPaymentOption?.id]);

  const getInvalidPatternByType = (type?: FieldType) => {
    switch (type) {
      case FieldType.EMAIL:
        return t('app.form-inputs.validation-errors.invalid-email');
      case FieldType.PHONE_COUNTRY_CODE:
        return t('app.form-inputs.validation-errors.invalid-phone');
    }
    return '';
  };

  const translate = ({ type, context: { renderInfo } }: TranslateArgs) => {
    switch (type) {
      case MessageType.VALUE_REQUIRED:
        if (isFormA11yEnabled) {
          const fieldLabel = renderInfo?.displayProperties?.label;

          if (fieldLabel) {
            return t(
              'app.form-inputs.validation-errors.required-field-with-label',
              {
                fieldLabel: fieldLabel.toLocaleLowerCase(
                  dateRegionalSettingsLocale,
                ),
              },
            );
          }
        }

        return t('app.form-inputs.validation-errors.required-field');
      case MessageType.INVALID_PATTERN:
        return getInvalidPatternByType(renderInfo?.type);
      default:
        return t(type);
    }
  };

  const participantsNumberField = getFieldFromSchema(
    formSchema,
    BookingRequestKeyMappings.NO_OF_PARTICIPANTS,
  );

  const emailField = getFieldFromSchema(
    formSchema,
    BookingRequestKeyMappings.EMAIL,
  );

  const onChangeInputField = (externalId: string, value: SubmissionValue) => {
    if (participantsNumberField?.externalId === externalId) {
      setNumberOfParticipants(
        Number(value),
        getServiceSlotIdentifier(singleSlotService.nestedSlot),
      );
    } else if (emailField?.externalId === externalId) {
      setEmail(value?.toString());
    }
  };

  const theme = settings.get(settingsParams.fieldsBorderStyle);

  const onValidateField = ({
    externalId,
    updatedValue,
  }: ValidateFieldArgs): MessageType | true => {
    const phoneId = getFieldFromSchema(
      formSchema,
      BookingRequestKeyMappings.PHONE,
    )?.externalId;
    const emailId = getFieldFromSchema(
      formSchema,
      BookingRequestKeyMappings.EMAIL,
    )?.externalId;

    switch (externalId) {
      case phoneId:
        if (isComplexPhoneField(externalId, formSchema)) {
          const phoneSubmission: ComplexPhoneData & {
            prefix: string;
          } = updatedValue as any;
          const { prefix, phone } = phoneSubmission;
          return validateComplexPhone(prefix, phone);
        }
        return true;
      case emailId:
        return validateEmail(updatedValue);
      default:
        return true;
    }
  };

  return (
    <Form
      isMobile={isMobile}
      ref={formRef}
      className={st(classes.root, { isMobile, theme })}
      data-hook={FormInputsDataHooks.FORM_COMPONENT}
      translateMessage={translate}
      theme={theme}
      schema={formSchema}
      onChange={(externalId: string, value: SubmissionValue) => {
        onChangeInputField(externalId, value);
      }}
      onValidateField={onValidateField}
    />
  );
};
export default FormInputs;
